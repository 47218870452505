import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faMediumM, faTelegram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';



import {  faSave } from '@fortawesome/free-solid-svg-icons';

const FooterLayout = () => {
  return (
    <div className='flex justify-center items-center space-x-4'>
        <a className='w-6' aria-label='Tradeplan Telegram' href='https://t.me/joinchat/nH94BmLef7NmYWJk' target={"_blank"}><FontAwesomeIcon icon={faTelegram} className="text-blue-500"></FontAwesomeIcon></a>
        <a className='w-6' aria-label='Tradeplan Twitter' href='https://twitter.com/TradeplanIO' target={"_blank"}><FontAwesomeIcon icon={faTwitter} className="text-blue-500"></FontAwesomeIcon></a>
        <a className='w-6' aria-label='Tradeplan Youtube' href='https://youtube.com/user/shaqqs69' target={"_blank"}><FontAwesomeIcon icon={faYoutube} className="text-red-700"></FontAwesomeIcon></a>
        <a className='w-6' aria-label='Tradeplan Medium' href='https://medium.com/@shakks' target={"_blank"}><FontAwesomeIcon icon={faMediumM} className="text-black"></FontAwesomeIcon></a>
    </div>
     
  );
};

export default FooterLayout;
